/*
 * @Desc:
 * @Author: JacksonZhou
 * @Date: 2021/11/28
 * @LastEditTime: 2022/01/04
 */
import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import "../css/categories.css";

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  let posts = data.allMarkdownRemark.nodes;
  let categories: any[] = [];
  posts.forEach((post) => {
    const current = categories.find(
      (category) => category.title === post.frontmatter.type
    );
    if (!current) {
      categories.push({
        title: post.frontmatter.type,
        count: 1,
      });
    } else {
      current.count = current.count + 1;
    }
  });

  return (
    <Layout location={location} title={siteTitle}>
      {categories.map((category) => {
        return (
          <div key={category.title} className="category">
            {category.title}（{category.count}）
          </div>
        );
      })}
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___createTime], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          type
        }
      }
    }
  }
`;
